import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Stack from "@components/ui/Stack/Stack"
import { metricsDescriptors } from "@framework/constants/metrics"
import { initArray, randomNumber } from "@utils/numberUtils"
import useToggle from "@framework/hooks/useToggle"
import without from "lodash/without"
import SettingsModal from "@components/modals/FacebookReportWinnersSettingsModal/FacebookReportWinnersSettingsModal"
import { AnalyticsFilter } from "@framework/types/creativeAnalytics"
import styles from "./Winners.module.scss"
import ReportDetailsModal from "../components/ReportDetails/ReportDetails"
import ReportCard from "../components/ReportCard/ReportCard"
import { MetaCampaignReportData, ViewType } from "../types"
import { generateCampaignReport } from "../mock"
import Table from "../components/Table/Table"
import ReportControlPanel from "../ReportControlPanel"
import mapper from "../General/mapper"
import PerformanceGoalCard, {
  PerformanceGoalCardProps,
} from "../components/PerformanceGoalCard/PerformanceGoalCard"
import ReportGroup from "../components/ReportCard/ReportGroup"
import ReportSummaryPreview from "../components/ReportCard/ReportSummaryPreview"
import ReportSummaryCard from "../components/ReportCard/ReportSummaryCard"

type WinnersPageProps = {
  className?: string
}

const WinnersPage: React.FC<WinnersPageProps> = observer(({ className }) => {
  const [view, setView] = React.useState<ViewType>("card")

  const [activeGoal, setActiveGoal] = React.useState<number>(0)

  const modal = useToggle()
  const settingsModal = useToggle()

  // TODO provide metrics list from filter
  const activeMetricsMock = metricsDescriptors

  // TODO API integration
  const reportsMock = React.useMemo(
    () =>
      initArray<MetaCampaignReportData>(
        performanceGoalOptions[activeGoal].total ?? 0,
        (idx) => generateCampaignReport(idx)
      ),
    [activeGoal]
  )

  // should be moved to global filter store
  const [activeGroupId, setActiveGroup] = React.useState<string>()

  const activeIndex = reportsMock.findIndex((it) => it.Id === activeGroupId)
  const active = reportsMock[activeIndex]
  const rest = without(reportsMock, active)
  const midIndex = Math.round(activeIndex / 3) * 3

  const before = rest.slice(0, midIndex)
  const after = rest.slice(midIndex)

  const renderGroup = (report: MetaCampaignReportData) => (
    <ReportGroup
      title="Headline"
      total={5}
      onToggleClick={() => setActiveGroup(report.Id)}
      key={report.Id}
    >
      <ReportSummaryPreview>
        {/* <ReportSummaryCard data={summaryReport} metrics={metricsDescriptors} /> */}
      </ReportSummaryPreview>
    </ReportGroup>
  )

  const renderReport = (report: MetaCampaignReportData) => (
    <ReportCard
      data={report}
      metrics={activeMetricsMock}
      onClick={modal.handleToggle}
      key={report.Id}
    />
  )

  const onAppliedFilters = (filters: AnalyticsFilter[][]) => {}

  return (
    <div className={clsx(styles.root, className)}>
      <Stack direction="column" gutter="20" align="stretch">
        <ReportControlPanel onAppliedFilters={onAppliedFilters} />

        <div className={styles.goalsGrid}>
          {performanceGoalOptions.map((item, idx) => (
            <PerformanceGoalCard
              {...item}
              active={activeGoal === idx}
              onClick={() => setActiveGoal(idx)}
              onEditClick={() => settingsModal.setOpened(true)}
              key={item.title}
            />
          ))}
        </div>

        {view === "table" && (
          <Table
            className={styles.table}
            mapper={mapper}
            data={reportsMock}
            summary={summaryReport}
          />
        )}

        {/* Grouping example */}
        {view === "card" && (
          <div className={styles.grid}>
            {active ? (
              <>
                {before.map(renderGroup)}

                <ReportGroup
                  className={clsx(styles.fullWidth)}
                  title="Headline"
                  total={5}
                  showAll
                  onToggleClick={() => setActiveGroup(undefined)}
                  key={active.Id}
                >
                  <div className={styles.goalsGrid}>
                    {reportsMock.map(renderReport)}
                  </div>
                </ReportGroup>

                {after.map(renderGroup)}
              </>
            ) : (
              <>{reportsMock.map(renderGroup)}</>
            )}
          </div>
        )}
      </Stack>

      {/* {modal.isOpened && ( */}
      {/*  <ReportDetailsModal open onClose={() => modal.setOpened(false)} /> */}
      {/* )} */}

      {settingsModal.isOpened && (
        <SettingsModal isOpen onClose={() => settingsModal.setOpened(false)} />
      )}
    </div>
  )
})

export default WinnersPage

const performanceGoalOptions: PerformanceGoalCardProps[] = [
  {
    title: "Winner",
    description: "Creatives, that reached your goal within the time period",
    status: "winner",
    total: randomNumber(0, 50),
  },
  {
    title: "Losers",
    description:
      "Creatives, that have not reached your goal within the time period",
    status: "loser",
    total: randomNumber(0, 50),
  },
  {
    title: "Scaling",
    description: "Creatives, that spend more of your goal",
    total: randomNumber(0, 50),
  },
  {
    title: "New",
    description: "Creatives, that launched within the specific period",
    total: randomNumber(0, 50),
  },
]

const summaryReport = generateCampaignReport(999, false)
