import React, { useEffect, useState } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { useStore } from "@store/index"
import Stack from "@components/ui/Stack/Stack"
import { metricsDescriptors } from "@framework/constants/metrics"
import useToggle from "@framework/hooks/useToggle"
import { useAlert } from "react-alert"
import Loader from "@components/ui/Loader/Loader"
import {
  AnalyticsCard,
  AnalyticsFilter,
  filterEqualityMapper,
  filterIncludesTypes,
  metricOptions,
} from "@framework/types/creativeAnalytics"
import {
  MetricsTrendType,
  MetricDescription,
  MetricType,
} from "@framework/types/metrics"
import KPITrendModal from "@pages/MetaAdsChannel/components/KPITrendChart/KPITrendModal"
import { apiDateFormatter } from "@services/utils"
import { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import { calculateMetricsTrend } from "@pages/MetaAdsChannel/components/utils"
import SimpleMultiselect from "@components/ui/DropDown/SimpleMultiselect"
import useOptionSearchList from "@framework/prototypes/useOptionsSearchList"
import Icon from "@components/ui/Icon/Icon"
import { Button } from "@components/ui/Button"
import Box from "@components/ui/Box/Box"
import Chip from "@components/ui/Chip/Chip"
import Typography from "@components/ui/Typography/Typography"
import ReportGroup from "@pages/MetaAdsChannel/components/ReportCard/ReportGroup"
import without from "lodash/without"
import ReportSummaryPreview from "@pages/MetaAdsChannel/components/ReportCard/ReportSummaryPreview"
import ReportSummaryCard from "@pages/MetaAdsChannel/components/ReportCard/ReportSummaryCard"
import {
  ADSAnalyticsGrouping,
  ADSAnalyticsGroupTypeEntity,
  ADSAnalyticsResponse,
  ReportEntitySettings,
} from "@services/creative-analytics"
import CreateReportModal from "@pages/MetaAdsChannel/components/Modals/CreateReportModal"
import EditReportModal from "@pages/MetaAdsChannel/components/Modals/EditReportModal"
import { DateRangeOption } from "@components/ui/DatePicker/types"
import { toJS } from "mobx"
import ReportControlPanel from "../ReportControlPanel"
import ReportCard from "../components/ReportCard/ReportCard"
import { MetaCampaignStatus } from "../types"
import mapper from "./mapper"
import Table from "../components/Table/Table"
import ReportDetailsModal from "../components/ReportDetails/ReportDetails"

import styles from "./General.module.scss"

type GeneralPageProps = {
  className?: string
}

const metricsOptionsArray = Object.entries(metricOptions).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
)

const GeneralPage: React.FC<GeneralPageProps> = observer(({ className }) => {
  const {
    accountStore: { accountId },
    analyticsFiltersStore: {
      activePeriodicity,
      filtersStatus,
      allFilters,
      setFiltersStatus,
      setActivePeriodicity,
      setAllFilters,
    },
    creativeAnalyticsStore: {
      adsCardsData,
      KPITrendsData,
      reportData,
      getKPITrends,
      createReport,
      updateReport,
      getReportsList,
      isLoading,
      isKPILoading,
    },
  } = useStore()

  const { view, filters, groupingType, sortByMetric, period } = allFilters
  const alert = useAlert()

  const [filteredCards, setFilteredCards] = React.useState<AnalyticsCard[]>(
    adsCardsData.AdCreatives
  )
  const [filteredGroups, setFilteredGroups] = useState<
    ADSAnalyticsResponse["data"]["Groupings"]
  >(adsCardsData.Groupings)
  const [activeMetrics, setActiveMetrics] = useState<MetricDescription[]>([])
  const [metricsTrend, setMetricsTrend] = useState<MetricsTrendType>({})
  const [report, setReport] = useState<{
    id: string
    name: string
    status: MetaCampaignStatus
  } | null>(null)
  const [colorMetricOptionsValues, setColorMetricOptionsValues] = useState<
    string[]
  >([])
  const [showColorMetricsSelect, setShowColorMetricsSelect] = useState(false)
  const [reportDetails, setReportDetails] = useState<AnalyticsCard>()
  const [activeGroup, setActiveGroup] = React.useState<string | undefined>()
  const [groupListViewOptions, setGroupListViewOptions] = useState<{
    active: ADSAnalyticsGroupTypeEntity | null
    before: ADSAnalyticsGroupTypeEntity[]
    after: ADSAnalyticsGroupTypeEntity[]
  }>({
    active: null,
    before: [],
    after: [],
  })

  const filtersRef = React.useRef(filters)

  const modal = useToggle()
  const KPImodal = useToggle()
  const createReportModal = useToggle()
  const editReportModal = useToggle()

  const summary = Object.entries(adsCardsData.Summary).reduce(
    (previousValue, currentValue) => {
      const [key, value] = currentValue
      return { ...previousValue, [key]: value.Value }
    },
    {}
  )

  const metricsOptions = useOptionSearchList({
    list: metricsOptionsArray ?? [],
  })

  const onAppliedFilters = (filters: AnalyticsFilter[][]) => {
    if (filters.length === 0) {
      setFilteredCards(adsCardsData.AdCreatives)
      setFilteredGroups(adsCardsData.Groupings)
      setAllFilters({ ...allFilters, filters: [] })
      filtersRef.current = []
      return
    }
    setAllFilters({ ...allFilters, filters })
  }

  const onMetricsFilterChange = (metrics: string[]) => {
    const filteredMetricsDescriptors = metricsDescriptors.filter((md) =>
      metrics.includes(md.name)
    )
    setActiveMetrics(filteredMetricsDescriptors)
  }

  const handleOpenKPItrendModal = (
    id: string,
    name: string,
    status: MetaCampaignStatus
  ) => {
    setReport({ id, name, status: status || "INACTIVE" })
    KPImodal.setOpened(true)
  }

  const handleOpenReportDetailsModal = (card: AnalyticsCard) => {
    setReportDetails(card)
    modal.handleToggle()
  }

  const handleActivePeriodicity = (
    periodicity: PerformanceReportPeriodicity
  ) => {
    setActivePeriodicity(periodicity)
  }

  const handleMetricsOptions = (value: string) => {
    setColorMetricOptionsValues((prev) =>
      prev.includes(value)
        ? prev.filter((it) => it !== value)
        : [...prev, value]
    )
  }

  const handleRemoveColorMetric = (filter: string) => {
    setColorMetricOptionsValues((prev) => prev.filter((it) => it !== filter))
  }

  const onCreateReport = async (name: string) => {
    const report: ReportEntitySettings = {
      name,
      time_period: {
        start_date: apiDateFormatter(period.range[0]),
        end_date: apiDateFormatter(period.range[1]),
        range_type: period.label,
        range_value: period.value,
      },
      filters: filters.map((filter) => ({
        name: filter[0].value,
        comparator: filter[1].value,
        value: filter[2].value,
      })),
      group: groupingType,
      metrics: activeMetrics.map((metric) => metric.name),
      view_mode: view,
      sort_option: sortByMetric
        ? {
            type: sortByMetric.value,
            order: sortByMetric.order,
          }
        : null,
    }
    if (accountId) {
      await createReport(accountId, report).then((res) => {
        if (!res) {
          createReportModal.setOpened(false)
          alert.success("Report created successfully")
          getReportsList(accountId)
        }
      })
    }
  }

  const onEditReport = async () => {
    if (reportData && accountId) {
      const report: ReportEntitySettings = {
        name: reportData?.settings.name,
        time_period: {
          start_date: apiDateFormatter(period.range[0]),
          end_date: apiDateFormatter(period.range[1]),
          range_type: period.label,
          range_value: period.value,
        },
        filters: filters.map((filter) => ({
          name: filter[0].value,
          comparator: filter[1].value,
          value: filter[2].value,
        })),
        group: groupingType,
        metrics: activeMetrics.map((metric) => metric.name),
        view_mode: view,
        sort_option: sortByMetric
          ? {
              type: sortByMetric.value,
              order: sortByMetric.order,
            }
          : null,
      }
      await updateReport(accountId, reportData.id, report).then((res) => {
        if (!res) {
          editReportModal.setOpened(false)
          alert.success("Report successfully updated")
          getReportsList(accountId)
        }
      })
      setFiltersStatus("reportView")
    }
  }

  useEffect(() => {
    if (reportData && !reportData.is_read_only && filtersStatus === "base") {
      setFiltersStatus("reportView")
    }
    if (!reportData) {
      setFiltersStatus("base")
    }
  }, [reportData, adsCardsData])

  useEffect(() => {
    if (!reportData && !adsCardsData) return

    let newPeriod: DateRangeOption = {
      label: "",
      value: "",
      range: period.range,
    }
    if (
      reportData?.settings.time_period.start_date &&
      reportData?.settings.time_period.end_date
    ) {
      newPeriod = {
        label: reportData?.settings.time_period.range_type,
        value: reportData?.settings.time_period.range_value,
        range: [
          new Date(reportData.settings.time_period.start_date),
          new Date(reportData.settings.time_period.end_date),
        ],
      }
    }
    if (reportData?.settings.metrics.length) {
      const appliedMetrics = metricsDescriptors.filter((md) =>
        reportData?.settings.metrics.includes(md.name)
      )
      setActiveMetrics(appliedMetrics)
    }
    let filtersArray: AnalyticsFilter[][] = []
    if (reportData?.settings?.filters?.length) {
      filtersArray = reportData?.settings.filters.map((f) => [
        { key: 1, value: f.name },
        { key: 2, value: f.comparator },
        { key: 3, value: f.value },
      ])
    }
    setAllFilters({
      view: reportData?.settings.view_mode || "card",
      filters: filtersArray,
      groupingType: reportData?.settings.group
        ? reportData.settings.group
        : "None",
      sortByMetric: reportData?.settings?.sort_option?.type
        ? {
            value: reportData.settings.sort_option.type,
            order: reportData.settings.sort_option.order,
          }
        : null,
      period: newPeriod,
    })
  }, [reportData, adsCardsData])

  useEffect(() => {
    const res = calculateMetricsTrend(
      adsCardsData.AdCreatives,
      colorMetricOptionsValues
    )
    setMetricsTrend(res)
  }, [adsCardsData, colorMetricOptionsValues])

  useEffect(() => {
    setFilteredCards(adsCardsData.AdCreatives)
    setFilteredGroups(adsCardsData.Groupings)
  }, [adsCardsData])

  useEffect(() => {
    if (!accountId || !report) return
    getKPITrends(accountId, report.id, {
      from: apiDateFormatter(period.range[0]),
      to: apiDateFormatter(period.range[1]),
      periodicity: activePeriodicity,
    })
  }, [report, activePeriodicity, allFilters])

  useEffect(() => {
    let result: AnalyticsCard[]
    if (reportData) {
      result = adsCardsData.AdCreatives
    } else {
      result = filteredCards.length ? filteredCards : adsCardsData.AdCreatives
    }

    let resultGroups =
      groupingType !== "None" && filteredGroups
        ? filteredGroups[groupingType]
        : []
    if (filtersRef.current.length > filters.length) {
      filtersRef.current = filters
      result = adsCardsData.AdCreatives
      resultGroups =
        groupingType !== "None" ? adsCardsData.Groupings[groupingType] : []
    }
    if (filters.length) {
      filters.forEach((filter) => {
        const firstFilter = filter[0]
        const secondFilter = filter[1]
        if (
          firstFilter.value === "campaigns" ||
          firstFilter.value === "adSets" ||
          firstFilter.value === "creativeType"
        ) {
          const thirdFilters = filter.filter((f) => f.key === 3)
          if (filterIncludesTypes[secondFilter.value] === "Includes") {
            result = result.filter((card) => {
              if (firstFilter.value === "campaigns")
                return thirdFilters.find((f) => f.value === card.CampaignName)
              if (firstFilter.value === "adSets")
                return thirdFilters.find((f) => f.value === card.AdsetName)
              return thirdFilters.find((f) => f.value === card.Type)
            })
          }
          if (filterIncludesTypes[secondFilter.value] === "Does not include") {
            result = result.filter((card) => {
              if (firstFilter.value === "campaigns")
                return !thirdFilters.find((f) => f.value === card.CampaignName)
              if (firstFilter.value === "adSets")
                return !thirdFilters.find((f) => f.value === card.AdsetName)
              return !thirdFilters.find((f) => f.value === card.Type)
            })
          }
        } else {
          const firstFilterValue = firstFilter.value
          const secondFilterValue = secondFilter.value
          const thirdFilterValue = Number(filter[2].value)
          const filterEqualityMapperType =
            filterEqualityMapper(secondFilterValue)
          result = result.filter((card) => {
            const metric = card[firstFilterValue]
            if (typeof metric === "object") {
              return filterEqualityMapperType(metric.Value, thirdFilterValue)
            }
            return false
          })
          resultGroups = resultGroups.filter((card) => {
            // @ts-ignore
            const metric = card.Performance[firstFilterValue]
            if (typeof metric === "object") {
              return filterEqualityMapperType(metric.Value, thirdFilterValue)
            }
            return false
          })
        }
      })
    }
    filtersRef.current = filters

    if (sortByMetric?.value && sortByMetric?.order) {
      const cards = [...result]
      cards.sort((a, b) => {
        const aMetric = a[sortByMetric.value] as MetricType
        const bMetric = b[sortByMetric.value] as MetricType
        if (sortByMetric.order === "asc") {
          if (aMetric.Value > bMetric.Value) return 1
          if (aMetric.Value < bMetric.Value) return -1
          return 0
        }
        if (aMetric.Value > bMetric.Value) return -1
        if (aMetric.Value < bMetric.Value) return 1
        return 0
      })

      setFilteredCards(cards)
    } else setFilteredCards(result)

    let resultFilteredGroups: ADSAnalyticsGrouping
    if (groupingType === "None") {
      resultFilteredGroups = adsCardsData.Groupings
    } else {
      // @ts-ignore
      resultFilteredGroups = { ...filteredGroups, [groupingType]: resultGroups }
    }
    setFilteredGroups(resultFilteredGroups)
  }, [adsCardsData, reportData, allFilters])

  useEffect(() => {
    if (filteredGroups) {
      let activeIndex = -1
      if (groupingType !== "None") {
        activeIndex = filteredGroups[groupingType]?.findIndex(
          (it) => it.Name === activeGroup
        )
      }
      if (activeIndex === -1 || groupingType === "None") {
        setGroupListViewOptions({
          active: null,
          before: [],
          after: [],
        })
        return
      }
      const active = filteredGroups[groupingType][activeIndex]
      const rest = without(filteredGroups[groupingType], active)
      const midIndex = Math.round(activeIndex / 3) * 3

      const before = rest.slice(0, midIndex)
      const after = rest.slice(midIndex)
      setGroupListViewOptions({
        active,
        before,
        after,
      })
    }
  }, [filteredGroups, activeGroup, allFilters])

  const renderGroup = (group: ADSAnalyticsGroupTypeEntity, index: number) => (
    <ReportGroup
      title={group.Name}
      titleOverflow
      total={group.Ads.length}
      onToggleClick={() => setActiveGroup(group.Name)}
    >
      <ReportSummaryPreview>
        <ReportSummaryCard
          data={group.Performance}
          metricsTrend={metricsTrend}
          metrics={activeMetrics}
        />
      </ReportSummaryPreview>
    </ReportGroup>
  )

  const renderReport = (report: AnalyticsCard) => (
    <ReportCard
      data={report}
      metricsTrend={metricsTrend}
      metrics={activeMetrics}
      onClick={() => handleOpenReportDetailsModal(report)}
      onOpenKPIChart={handleOpenKPItrendModal}
      key={report.Id}
    />
  )

  console.log("### filteredCards", toJS(filteredCards))

  return (
    <div className={clsx(styles.root, className)}>
      <Stack direction="column" gutter="20" align="stretch">
        <ReportControlPanel
          onAppliedFilters={onAppliedFilters}
          onMetricsFilter={onMetricsFilterChange}
          onCreateReportOpen={() => createReportModal.setOpened(true)}
          onEditReportSaveOpen={() => editReportModal.setOpened(true)}
        />

        {!isLoading && (
          <Box>
            <Typography type="body2" weight="bold">
              Color sorting by
            </Typography>
            <div className={styles.colorMetricsPanel}>
              <Button
                color="primary"
                variant="outlined"
                before={<Icon name="plus" />}
                onClick={() => setShowColorMetricsSelect((prev) => !prev)}
              >
                Add metrics
              </Button>
              {showColorMetricsSelect && (
                <div className={styles.colorMetricsSelect}>
                  <SimpleMultiselect
                    withSearch
                    onSelect={handleMetricsOptions}
                    value={colorMetricOptionsValues}
                    placeholder="Add metric"
                    {...metricsOptions}
                  />
                </div>
              )}
              {colorMetricOptionsValues.map((filter) => {
                if (!filter) return null
                return (
                  <Chip
                    type="contained"
                    color="secondary"
                    endIcon={
                      <Icon
                        name="cross"
                        onClick={() => handleRemoveColorMetric(filter)}
                      />
                    }
                    className={styles.filterChip}
                    key={filter}
                  >
                    {filter}
                  </Chip>
                )
              })}
            </div>
          </Box>
        )}

        {isLoading && <Loader />}

        {view === "table" && !isLoading && (
          <Table
            className={styles.table}
            mapper={mapper}
            data={filteredCards}
            // summary={summary}
          />
        )}

        {view === "card" && groupingType === "None" && !isLoading && (
          <div className={styles.grid}>
            {filteredCards.map((card) => (
              <ReportCard
                data={card}
                metricsTrend={metricsTrend}
                metrics={activeMetrics}
                onClick={() => handleOpenReportDetailsModal(card)}
                onOpenKPIChart={handleOpenKPItrendModal}
                key={card.Id}
              />
            ))}
          </div>
        )}

        <div className={styles.gridGroups}>
          {groupListViewOptions.active && !isLoading && filteredGroups ? (
            <>
              {groupListViewOptions.before.map(renderGroup)}

              <ReportGroup
                className={clsx(styles.fullWidth)}
                title={groupListViewOptions.active?.Name}
                total={groupListViewOptions.active?.Ads.length}
                showAll
                onToggleClick={() => setActiveGroup(undefined)}
              >
                <div className={styles.goalsGrid}>
                  {filteredCards
                    .filter((card) =>
                      groupListViewOptions.active?.Ads.includes(card.Id)
                    )
                    .map(renderReport)}
                </div>
              </ReportGroup>

              {groupListViewOptions.after.map(renderGroup)}
            </>
          ) : !groupListViewOptions.active &&
            !isLoading &&
            groupingType !== "None" ? (
            <>
              {filteredGroups && filteredGroups[groupingType].map(renderGroup)}
            </>
          ) : null}
        </div>
      </Stack>

      <CreateReportModal
        isOpen={createReportModal.isOpened}
        onClose={() => createReportModal.setOpened(false)}
        onCreateReport={onCreateReport}
      />

      <EditReportModal
        reportName={reportData?.settings.name as string}
        isOpen={editReportModal.isOpened}
        onClose={() => editReportModal.setOpened(false)}
        onEditReport={onEditReport}
      />

      <ReportDetailsModal
        open={modal.isOpened}
        onClose={() => modal.setOpened(false)}
        reportDetails={reportDetails as AnalyticsCard}
        metricsTrend={metricsTrend}
      />

      <KPITrendModal
        activePeriodicity={activePeriodicity}
        onActivePeriodicity={handleActivePeriodicity}
        report={report}
        data={KPITrendsData}
        open={KPImodal.isOpened}
        isKPILoading={isKPILoading}
        onClose={() => KPImodal.setOpened(false)}
      />
    </div>
  )
})

export default GeneralPage
