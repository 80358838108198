import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"

import Stack from "@components/ui/Stack/Stack"
import { Button, ButtonGroup } from "@components/ui/Button"
import Box from "@components/ui/Box/Box"
import Icon from "@components/ui/Icon/Icon"
import ButtonDatePicker from "@components/ui/DatePicker/ButtonDatePicker"
import IconButton from "@components/ui/Button/IconButton"
import SimpleSelect from "@components/ui/DropDown/SimpleSelect"
import Typography from "@components/ui/Typography/Typography"
import TextField from "@components/ui/TextField/TextField"
import SimpleMultiselect from "@components/ui/DropDown/SimpleMultiselect"
import useOptionSearchList from "@framework/prototypes/useOptionsSearchList"
import Chip from "@components/ui/Chip/Chip"
import { DateRangeOption } from "@components/ui/DatePicker/types"
import { useStore } from "@root/store"
import { apiDateFormatter } from "@services/utils"
import {
  AnalyticsFilter,
  creativeTypes,
  defaultMetrics,
  filterEqualityTypes,
  filterIncludesTypes,
  filterOptions,
  groupByOptions,
  metricOptions,
} from "@framework/types/creativeAnalytics"
import {
  ADSAnalyticsGroupType,
  ReportResponse,
} from "@services/creative-analytics"
import styles from "./ReportControlPanel.module.scss"

interface ReportControlPanelProps {
  reportData?: ReportResponse["data"] | null
  onAppliedFilters: (filters: AnalyticsFilter[][]) => void
  onMetricsFilter?: (metrics: string[]) => void
  onCreateReportOpen?: () => void
  onEditReportSaveOpen?: () => void
}

const filterOptionsArray = Object.entries(filterOptions).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
)

const filterOptionsMetricsArray = Object.keys(filterEqualityTypes)
const filterOptionsMainArray = Object.keys(filterIncludesTypes)
const creativeTypeOptionsArray = Object.keys(creativeTypes)

const metricsOptionsArray = Object.entries(metricOptions).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
)

const metricsSortByOptionsArray: { value: string; label: string }[] = []
Object.entries(metricOptions).forEach(([key, value]) => {
  metricsSortByOptionsArray.push({
    value: `${key}-(desc)`,
    label: `${value} (desc)`,
  })
  metricsSortByOptionsArray.push({
    value: `${key}-(asc)`,
    label: `${value} (asc)`,
  })
})

const groupByOptionsArray = Object.entries(groupByOptions).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
)

const ReportControlPanel: React.FC<ReportControlPanelProps> = observer(
  ({
    onAppliedFilters,
    onMetricsFilter,
    onCreateReportOpen,
    onEditReportSaveOpen,
  }) => {
    const {
      accountStore: { accountId },
      creativeAnalyticsStore: {
        getADSCards,
        adsCardsData,
        reportData,
        isLoading,
      },
      analyticsFiltersStore: {
        datePickerOptions,
        allFilters,
        filtersStatus,
        setFiltersStatus,
        setAllFilters,
      },
    } = useStore()

    const { view, groupingType, period } = allFilters
    const [showFilters, setShowFilters] = useState(false)
    const [showMetrics, setShowMetrics] = useState(false)
    const [showSortBy, setShowSortBy] = useState(false)
    const [showGroupBy, setShowGroupBy] = useState(false)

    const [campaignsList, setCampaignsList] = useState<string[]>([])
    const [adSetsList, setAdSetsList] = useState<string[]>([])
    const [filterValue, setFilterValue] = useState<string>("")
    const [filterOptionsMetrics, setFilterOptionsMetrics] = useState<string>("")
    const [filterOptionsMain, setFilterOptionsMain] = useState<string>("")
    const [metricSortByValue, setMetricSortByValue] = useState<string>("")
    const [groupByValue, setGroupByValue] = useState<
      ADSAnalyticsGroupType | "None"
    >(groupingType)
    const [filterTextValue, setFilterTextValue] = useState<string>("")
    const [creativeTypeOptions, setCreativeTypeOptions] = useState<string[]>([])
    const [filterCampaignsValue, setFilterCampaignsValue] = useState<string[]>(
      []
    )
    const [filterAdsetsValue, setFilterAdsetsValue] = useState<string[]>([])
    const [metricOptionsValues, setMetricOptionsValues] =
      useState<string[]>(defaultMetrics)

    const [singleFilter, setSingleFilter] = useState<AnalyticsFilter[]>([])
    const [appliedFiltersStrings, setAppliedFiltersStrings] = useState<
      string[]
    >([])
    const [appliedFilters, setAppliedFilters] = useState<AnalyticsFilter[][]>(
      []
    )

    const metricsOptions = useOptionSearchList({
      list: metricsOptionsArray ?? [],
    })

    const metricsSortByOptions = useOptionSearchList({
      list: metricsSortByOptionsArray ?? [],
    })

    const groupByOptions = useOptionSearchList({
      list: groupByOptionsArray ?? [],
    })

    const filterOptions = useOptionSearchList({
      list:
        groupByValue === "None"
          ? filterOptionsArray ?? []
          : filterOptionsArray.filter(
              (it) =>
                it.value !== "campaigns" &&
                it.value !== "adSets" &&
                it.value !== "creativeType"
            ) ?? [],
    })

    const handleFilterValue = (value: string) => {
      setFilterValue(value)
      setSingleFilter([{ key: 1, value }])
      setFilterOptionsMain("")
      setFilterOptionsMetrics("")
      setCreativeTypeOptions([])
      setFilterCampaignsValue([])
      setFilterAdsetsValue([])
      setFilterTextValue("")
    }

    useEffect(() => {
      if (!accountId) return
      if (reportData?.settings.time_period.start_date) {
        getADSCards(accountId, {
          from: apiDateFormatter(
            new Date(reportData.settings.time_period.start_date)
          ),
          to: apiDateFormatter(
            new Date(reportData.settings.time_period.end_date)
          ),
        })
        return
      }
      getADSCards(accountId, {
        from: apiDateFormatter(period?.range[0]),
        to: apiDateFormatter(period.range[1]),
      })
    }, [accountId, reportData])

    useEffect(() => {}, [
      reportData,
      filterValue,
      filterOptionsMain,
      filterOptionsMetrics,
      creativeTypeOptions,
      filterAdsetsValue,
      filterCampaignsValue,
      metricOptionsValues,
      singleFilter,
      metricSortByValue,
    ])

    useEffect(() => {
      onAppliedFilters(appliedFilters)
    }, [appliedFilters])

    useEffect(() => {
      if (reportData) {
        if (reportData.settings.filters) {
          const result: string[] = []
          const res: AnalyticsFilter[][] = reportData?.settings.filters.map(
            (f) => [
              { key: 1, value: f.name },
              { key: 2, value: f.comparator },
              { key: 3, value: f.value },
            ]
          )
          res.forEach((filter) => {
            const f = filter.map((it) => it.value).join(" ")
            result.push(f)
          })
          setAppliedFiltersStrings([...result])
          setAppliedFilters([...res])
        }
        if (reportData.settings.sort_option?.type) {
          setMetricSortByValue(
            `${reportData.settings.sort_option.type}-(${reportData.settings.sort_option.order})`
          )
          setAllFilters({
            ...allFilters,
            sortByMetric: {
              value: reportData.settings.sort_option.type,
              order: reportData.settings.sort_option.order,
            },
          })
        }
        if (reportData.settings.metrics.length) {
          setMetricOptionsValues(reportData.settings.metrics)
        }
      }
    }, [reportData, adsCardsData])

    useEffect(() => {
      if (onMetricsFilter) onMetricsFilter(metricOptionsValues)
    }, [metricOptionsValues])

    useEffect(() => {
      if (onMetricsFilter) onMetricsFilter(metricOptionsValues)
    }, [])

    useEffect(() => {
      setFilterValue("")
      setSingleFilter([])
      setFilterOptionsMain("")
      setFilterOptionsMetrics("")
      setCreativeTypeOptions([])
      setFilterCampaignsValue([])
      setFilterAdsetsValue([])
      setFilterTextValue("")
      setMetricSortByValue("")
    }, [])

    useEffect(() => {
      const campaigns: Set<string> = new Set()
      const adSets: Set<string> = new Set()
      if (adsCardsData.AdCreatives.length) {
        adsCardsData.AdCreatives.forEach((c) => {
          if (c.CampaignName) {
            campaigns.add(c.CampaignName)
          }
        })
        adsCardsData.AdCreatives.forEach((c) => {
          if (c.AdsetName) {
            adSets.add(c.AdsetName)
          }
        })
      }
      setCampaignsList(Array.from(campaigns))
      setAdSetsList(Array.from(adSets))
    }, [adsCardsData])

    const handleFilterOptionsMain = (value: string) => {
      setFilterOptionsMain((prevState) => {
        setSingleFilter((prev) => {
          let res = []
          if (prev.find((i) => i.key === 2)) {
            res = prev.filter((it) => it.value !== prevState)
            return [...res, { key: 2, value }]
          }
          return [...prev, { key: 2, value }]
        })
        return value
      })
    }

    const handleFilterOptionsMetrics = (value: string) => {
      setFilterOptionsMetrics((prevState) => {
        setSingleFilter((prev) => {
          let res = []
          if (prev.find((i) => i.key === 2)) {
            res = prev.filter((it) => it.value !== prevState)
            return [...res, { key: 2, value }]
          }
          return [...prev, { key: 2, value }]
        })
        return value
      })
    }

    const handleSortByMetrics = (value: string) => {
      setMetricSortByValue(value)
      const regex = /(\w+)-?\((desc|asc)\)/
      const match = value.match(regex)
      if (match) {
        setAllFilters({
          ...allFilters,
          sortByMetric: { value: match[1], order: match[2] as "desc" | "asc" },
        })
        if (filtersStatus === "reportView") {
          setFiltersStatus("reportEditing")
        }
      }
    }

    const handleGroupBy = (value: ADSAnalyticsGroupType | "None") => {
      setGroupByValue(value)
      if (filtersStatus === "reportView") {
        setFiltersStatus("reportEditing")
      }
      setAllFilters({ ...allFilters, groupingType: value })
    }

    const handleThirdFilter = (value: string, type: string) => {
      let val: string = ""
      if (type === "creativeType") {
        val = value
        setCreativeTypeOptions((prevState) =>
          prevState.includes(value)
            ? prevState.filter((it) => it !== value)
            : [...prevState, value]
        )
      }
      if (type === "adsetsType") {
        val = value
        setFilterAdsetsValue((prevState) =>
          prevState.includes(value)
            ? prevState.filter((it) => it !== value)
            : [...prevState, value]
        )
      }
      if (type === "campaignType") {
        val = value
        setFilterCampaignsValue((prevState) =>
          prevState.includes(value)
            ? prevState.filter((it) => it !== value)
            : [...prevState, value]
        )
      }
      setSingleFilter((prev) => {
        let res = []
        if (prev.find((i) => i.value === value && i.key === 3)) {
          res = prev.filter((it) => it.value !== value)
          return res
        }

        return [...prev, { key: 3, value: val }]
      })
    }

    const handleFilterTextValue = (value: string) => {
      setFilterTextValue(value)
      setSingleFilter((prev) => {
        let res = []
        if (prev.findIndex((i) => i.key === 3)) {
          res = prev.filter((it) => it.key !== 3)
          return [...res, { key: 3, value }]
        }
        return [...prev, { key: 3, value }]
      })
    }

    const handleMetricsOptions = (value: string) => {
      if (filtersStatus === "reportView") {
        setFiltersStatus("reportEditing")
      }
      setMetricOptionsValues((prev) =>
        prev.includes(value)
          ? prev.filter((it) => it !== value)
          : [...prev, value]
      )
    }

    const handleApplyFilter = () => {
      if (singleFilter.length < 3) return
      const res = singleFilter
        .sort((a, b) => a.key - b.key)
        .map((it) => it.value)
        .join(" ")
      setAppliedFiltersStrings((prev) => [...prev, res])
      setAppliedFilters((prev) => [...prev, singleFilter.sort()])
      setShowFilters(false)
      if (filtersStatus === "reportView") {
        setFiltersStatus("reportEditing")
      }
    }

    const handleRemoveFilter = (filter: string, index: number) => {
      setAppliedFiltersStrings((prev) => prev.filter((it) => it !== filter))
      setAppliedFilters((prev) => prev.filter((it, i) => i !== index))
      if (filtersStatus === "reportView") {
        setFiltersStatus("reportEditing")
      }
    }

    const handleSetPeriod = (period: DateRangeOption) => {
      if (filtersStatus === "reportView") {
        setFiltersStatus("reportEditing")
      }
      setAllFilters({ ...allFilters, period })
      if (!accountId) return
      getADSCards(accountId, {
        from: apiDateFormatter(period.range[0]),
        to: apiDateFormatter(period.range[1]),
      })
    }

    if (isLoading) return <div />

    return (
      <Box>
        <Stack
          className={styles.reportControlPanel}
          direction="row"
          gutter="8"
          justify="space-between"
        >
          <Stack direction="row" gutter="8">
            <Button color="secondary" before={<Icon name="refresh" />}>
              Sync
            </Button>
            {period ? (
              <ButtonDatePicker
                range={period}
                onRangeChange={handleSetPeriod}
                rangeOptions={datePickerOptions}
              />
            ) : null}
            <Button
              color="secondary"
              before={<Icon name="filter" />}
              onClick={() =>
                setShowFilters((prev) => {
                  setShowMetrics(false)
                  return !prev
                })
              }
              after={
                singleFilter.length > 0 ? (
                  <span className={styles.filtersCountIcon}>
                    <Typography type="body2" color="light">
                      {appliedFiltersStrings.length}
                    </Typography>
                  </span>
                ) : null
              }
            >
              Filters
            </Button>
            <Button
              color="secondary"
              before={<Icon name="stack" />}
              onClick={() => setShowGroupBy((prev) => !prev)}
            >
              {groupByValue === "None" ? "Group" : groupByValue}
            </Button>
            <Button
              color="secondary"
              before={<Icon name="eye" />}
              onClick={() =>
                setShowMetrics((prev) => {
                  setShowFilters(false)
                  return !prev
                })
              }
            >
              Metrics
            </Button>
            <Button
              color="secondary"
              before={<Icon name="sort-desc" />}
              onClick={() => setShowSortBy((prev) => !prev)}
            >
              Sort by
            </Button>
          </Stack>
          {showFilters && (
            <div className={styles.filterMenu}>
              <Typography type="body1">Where </Typography>
              <SimpleSelect
                className={styles.select}
                withSearch
                titleItems
                onSelect={handleFilterValue}
                value={filterValue}
                {...filterOptions}
              />
              {filterValue === "campaigns" ||
              filterValue === "adSets" ||
              filterValue === "creativeType" ? (
                <SimpleSelect
                  className={styles.select}
                  options={filterOptionsMainArray}
                  onSelect={handleFilterOptionsMain}
                  value={filterOptionsMain}
                  renderValue={(value) => filterIncludesTypes[value]}
                />
              ) : null}

              {filterValue !== "campaigns" &&
              filterValue !== "adSets" &&
              filterValue !== "creativeType" ? (
                <SimpleSelect
                  className={styles.select}
                  options={filterOptionsMetricsArray}
                  onSelect={handleFilterOptionsMetrics}
                  value={filterOptionsMetrics}
                  renderValue={(value) => filterEqualityTypes[value]}
                />
              ) : null}

              {filterValue !== "campaigns" &&
              filterValue !== "adSets" &&
              filterValue !== "creativeType" ? (
                <TextField
                  value={filterTextValue}
                  onChange={(e) => handleFilterTextValue(e.target.value)}
                  className={styles.select}
                  placeholder="Value"
                  type="number"
                  step="0.01"
                />
              ) : null}

              {filterValue === "creativeType" ? (
                <SimpleMultiselect
                  className={styles.select}
                  value={creativeTypeOptions}
                  onSelect={(value) => handleThirdFilter(value, "creativeType")}
                  options={creativeTypeOptionsArray}
                  renderValue={(value) => creativeTypes[value]}
                />
              ) : null}

              {filterValue === "campaigns" ? (
                <SimpleMultiselect
                  className={styles.select}
                  options={campaignsList}
                  onSelect={(value) => handleThirdFilter(value, "campaignType")}
                  value={filterCampaignsValue}
                />
              ) : null}
              {filterValue === "adSets" ? (
                <SimpleMultiselect
                  className={styles.select}
                  options={adSetsList}
                  onSelect={(value) => handleThirdFilter(value, "adsetsType")}
                  value={filterAdsetsValue}
                />
              ) : null}

              <Button
                color="primary"
                className={styles.apply}
                onClick={handleApplyFilter}
              >
                Apply
              </Button>
            </div>
          )}
          {showMetrics && (
            <div className={styles.metricsMenu}>
              <SimpleMultiselect
                className={styles.select}
                withSearch
                onSelect={handleMetricsOptions}
                value={metricOptionsValues}
                placeholder="Search metrics"
                {...metricsOptions}
              />
            </div>
          )}
          {showSortBy && (
            <div className={styles.sortByMenu}>
              <SimpleSelect
                className={styles.select}
                withSearch
                onSelect={handleSortByMetrics}
                value={metricSortByValue}
                {...metricsSortByOptions}
              />
            </div>
          )}
          {showGroupBy && (
            <div className={styles.groupByMenu}>
              <SimpleSelect
                className={styles.select}
                onSelect={(value) =>
                  handleGroupBy(value as ADSAnalyticsGroupType)
                }
                value={groupByValue}
                {...groupByOptions}
              />
            </div>
          )}
          <Stack direction="row" gutter="8" justify="flex-end">
            <ButtonGroup>
              <IconButton
                variant="contained"
                color={view === "card" ? "primary" : "secondary"}
                onClick={() => {
                  if (filtersStatus === "reportView") {
                    setFiltersStatus("reportEditing")
                  }
                  setAllFilters({ ...allFilters, view: "card" })
                }}
              >
                <Icon name="dashboard1" />
              </IconButton>
              <IconButton
                variant="contained"
                color={view === "table" ? "primary" : "secondary"}
                onClick={() => {
                  if (filtersStatus === "reportView") {
                    setFiltersStatus("reportEditing")
                  }
                  setAllFilters({ ...allFilters, view: "table" })
                }}
              >
                <Icon name="menu" />
              </IconButton>
            </ButtonGroup>
            {filtersStatus === "base" && (
              <Button
                color="primary"
                before={<Icon name="add" />}
                onClick={onCreateReportOpen}
              >
                Create report
              </Button>
            )}
            {(filtersStatus === "reportEditing" ||
              filtersStatus === "reportView") && (
              <Button
                color="primary"
                disabled={filtersStatus === "reportView"}
                onClick={onEditReportSaveOpen}
              >
                Save changes
              </Button>
            )}
          </Stack>
        </Stack>

        {appliedFiltersStrings.length ? (
          <div className={styles.filteredBy}>
            {appliedFiltersStrings.map((filter, index) => {
              if (!filter) return null
              return (
                <Chip
                  type="outlined"
                  color="primary"
                  endIcon={
                    <Icon
                      name="cross"
                      onClick={() => handleRemoveFilter(filter, index)}
                    />
                  }
                  className={styles.filterChip}
                  key={filter}
                >
                  {filter}
                </Chip>
              )
            })}
          </div>
        ) : null}
      </Box>
    )
  }
)

export default ReportControlPanel
