import React from "react"
import clsx from "clsx"
import get from "lodash/get"

import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Box from "@components/ui/Box/Box"
import Icon from "@components/ui/Icon/Icon"
import { Button } from "@components/ui/Button"
import { MetricDescription, MetricsTrendType } from "@framework/types/metrics"
import NoData from "@components/ui/NoData/NoData"
import Image from "@components/ui/Image/Image"
import {
  MetaCampaignReportData,
  MetaCampaignStatus,
} from "@pages/MetaAdsChannel/types"
import StatusBadge from "@pages/MetaAdsChannel/components/StatusBadge"
import MetricValueChip from "../MetricValueChip"
import ReportTitle from "../ReportTitle"

import styles from "./ReportCard.module.sass"

interface ReportCardProps {
  data: MetaCampaignReportData
  metricsTrend?: MetricsTrendType
  onOpenKPIChart?: (
    id: string,
    name: string,
    status: MetaCampaignStatus
  ) => void
  metrics?: MetricDescription[]
  active?: boolean
  disabled?: boolean
  onClick?: React.MouseEventHandler
}

export const ReportCard: React.FC<ReportCardProps> = ({
  data,
  metricsTrend,
  onOpenKPIChart,
  active = false,
  disabled = false,
  onClick,
  metrics,
}) => (
  <Box
    className={clsx(styles.root, {
      [styles.active]: active,
      [styles.disabled]: disabled,
      [styles.clickable]: !!onClick,
    })}
    onClick={onClick}
    elevation={1}
  >
    <div className={styles.header}>
      <ReportTitle textClassName={styles.link}>{data.AdsetName}</ReportTitle>

      <Button variant="ghost" color="secondary" size="small">
        <Icon name="three-dots" />
      </Button>
    </div>

    <StatusBadge status={data.Status} />

    <div className={styles.previewContainer}>
      {data.Type === "IMAGE" ? (
        <Image src={data.ThumbnailURL} className={styles.image} alt="Preview" />
      ) : data.Type === "VIDEO" ? (
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        <iframe src={data.VideoURL} className={styles.image} loading="lazy" />
      ) : null}

      <Typography
        className={styles.views}
        type="caption3"
        color="black03Color"
        title="People are watching"
      >
        <Icon name="eye" />
        {/* {numberWithCommas(data.totalViews ?? 0)} */}
      </Typography>
    </div>

    <Stack
      className={styles.metricsContainer}
      gutter="4"
      direction="column"
      align="stretch"
    >
      <Stack direction="row" justify="space-between" align="center">
        <Typography color="primary" type="h4" weight="bold">
          Metrics
        </Typography>

        <Button
          size="small"
          color="primary"
          variant="outlined"
          before={<Icon name="statistics" />}
          onClick={(e) => {
            if (onOpenKPIChart)
              onOpenKPIChart(
                data.Id,
                data.AdsetName,
                data.Status as MetaCampaignStatus
              )
            e.stopPropagation()
          }}
        >
          KPI Trends
        </Button>
      </Stack>

      <Stack
        className={styles.metricHeader}
        direction="row"
        justify="space-between"
        align="center"
      >
        <Typography color="primary" weight="bold" type="caption2">
          Campaign
        </Typography>
        <Typography weight="bold" type="caption2">
          {data.CampaignName}
        </Typography>
      </Stack>
      <Stack
        className={styles.metricHeader}
        direction="row"
        justify="space-between"
        align="center"
      >
        <Typography color="primary" weight="bold" type="caption2">
          Ad Set
        </Typography>
        <Typography className={styles.adsetName} weight="bold" type="caption2">
          {data.AdsetName}
        </Typography>
      </Stack>

      {metrics?.map((metric) => {
        const value = get(data, metric.name, null)
        return (
          <Stack
            direction="row"
            justify="space-between"
            align="center"
            key={metric.name}
          >
            <Typography color="primary" weight="bold" type="caption2">
              {metric.label}
            </Typography>

            <Typography weight="bold" type="caption2">
              <MetricValueChip
                className={styles.metricChip}
                value={value}
                metric={metric}
                metricsTrend={metricsTrend as MetricsTrendType}
              />
            </Typography>
          </Stack>
        )
      }) ?? <NoData />}
    </Stack>
  </Box>
)

export default ReportCard
