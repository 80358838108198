import React, { useEffect } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Modal from "@components/ui/Modal/Modal"
import Icon from "@components/ui/Icon/Icon"
import Templates from "@components/ui/Templates"
import IconButton from "@components/ui/Button/IconButton"
import { AnalyticsCard } from "@framework/types/creativeAnalytics"
import { useStore } from "@root/store"
import { MetricsTrendType } from "@framework/types/metrics"
import DetailsSection from "./DetailsSection"
import MediaSection from "./MediaSection"
import StatusBadge from "../StatusBadge"
import ReportTitle from "../ReportTitle"

import styles from "./ReportDetails.module.sass"

interface ReportDetailsProps {
  reportDetails: AnalyticsCard
  metricsTrend: MetricsTrendType
  open: boolean
  onClose: () => void
}

export const ReportDetailsModal: React.FC<ReportDetailsProps> = observer(
  ({ open, onClose, reportDetails, metricsTrend }) => {
    const {
      accountStore: { accountId },
      creativeAnalyticsStore: {
        getDropOffHistogram,
        dropOffHistogramData,
        isDropOffHistogramLoading,
      },
    } = useStore()

    useEffect(() => {
      if (!accountId || !reportDetails) return
      getDropOffHistogram(accountId, reportDetails.Id)
    }, [accountId, reportDetails])

    return (
      <Modal
        className={clsx(styles.root)}
        isOpen={open}
        onClose={onClose}
        hideHeader
      >
        <div className={styles.header}>
          <Templates.TripleHeader
            left={
              <div className={styles.title}>
                <ReportTitle>{reportDetails?.AdsetName}</ReportTitle>
                <StatusBadge status={reportDetails?.Status} short />
              </div>
            }
            right={
              <>
                <IconButton variant="contained" color="secondary">
                  <Icon name="arrow-right" rotateAngle={180} />
                </IconButton>
                <IconButton variant="contained" color="secondary">
                  <Icon name="arrow-right" />
                </IconButton>
                <IconButton
                  variant="contained"
                  color="secondary"
                  onClick={onClose}
                >
                  <Icon name="cross" />
                </IconButton>
              </>
            }
          />
        </div>

        <div className={clsx(styles.body)}>
          <MediaSection
            data={reportDetails}
            dropOffHistogramData={dropOffHistogramData}
            isLoading={isDropOffHistogramLoading}
          />

          <DetailsSection data={reportDetails} metricsTrend={metricsTrend} />
        </div>
      </Modal>
    )
  }
)

export default ReportDetailsModal
