import React, { useEffect, useRef, useState } from "react"
import { Line } from "react-chartjs-2"
import clsx from "clsx"
import {
  Chart as ChartJS,
  CategoryScale,
  LineElement,
  ChartData,
  Filler,
  Tooltip,
} from "chart.js"

import { areaChartOptions } from "./config"
import { NULL } from "../utils"
import { createLineGradient, createLinearAreaGradient } from "./utils"

import styles from "./AreaChart.module.scss"

ChartJS.register(CategoryScale, LineElement, Tooltip, Filler)

export type AreaChartProps = {
  className?: string
  data: number[]
  labels: string[]
}

export const chartDataProvider = (
  labels: string[],
  data: number[],
  chart: ChartJS | null
): ChartData<"line"> => ({
  labels,
  datasets: [
    {
      type: "line" as const,
      data,
      borderWidth: 2,
      tension: 0.5,
      borderColor: createLineGradient(chart?.ctx, chart?.chartArea),
      pointBackgroundColor: "white",
      fill: {
        target: "origin",
        above: createLinearAreaGradient(chart?.ctx, chart?.chartArea),
      },
    },
  ],
})

const AreaChart: React.FC<AreaChartProps> = React.memo(
  ({ data, labels, className }) => {
    const chartRef = useRef<ChartJS<"line">>(null)

    const [chartData, setChartData] = useState<ChartData<"line">>({
      datasets: [],
    })

    useEffect(() => {
      setChartData(chartDataProvider(labels, data, chartRef.current))
    }, [data])

    return (
      <div className={clsx(styles.root, className)}>
        <Line
          ref={chartRef}
          data={chartData}
          options={{
            ...areaChartOptions,
            scales: {
              ...areaChartOptions.scales,
              x: {
                ...areaChartOptions.scales?.x,
                title: {
                  display: true,
                  text: "Time (s)",
                },
              },
              y: {
                ...areaChartOptions.scales?.y,
                title: {
                  display: true,
                  text: "Viewers",
                },
              },
            },
          }}
          height={NULL}
          width={NULL}
        />
      </div>
    )
  }
)

export default AreaChart
