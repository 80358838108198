import { observable, action } from "mobx"
import { ID } from "@framework/types/types"
import creativeAnalyticsService, {
  ADSAnalyticsResponse,
  DropOffHistogramResponse,
  KPITrendsResponse,
  ReportEntity,
  ReportEntitySettings,
  ReportResponse,
} from "@services/creative-analytics"

class CreativeAnalyticsStore {
  @observable isLoading: boolean = false

  @observable isKPILoading: boolean = false

  @observable isDropOffHistogramLoading: boolean = false

  @observable isReportLoading: boolean = false

  @observable error: string | null = null

  @observable adsCardsData: ADSAnalyticsResponse["data"] = {
    AdCreatives: [],
    Summary: {},
    Groupings: {
      ByCreative: [
        {
          Name: "",
          Performance: {},
          Ads: [],
        },
      ],
      ByName: [
        {
          Name: "",
          Performance: {},
          Ads: [],
        },
      ],
      ByTitle: [
        {
          Name: "",
          Performance: {},
          Ads: [],
        },
      ],
    },
  }

  @observable KPITrendsData: KPITrendsResponse["data"] = {}

  @observable dropOffHistogramData: DropOffHistogramResponse["data"] = []

  @observable reportData: ReportResponse["data"] | null = null

  @observable reportsList: ReportEntity[] | [] = []

  @action getADSCards = async (
    accountId: ID,
    datePair: { from: string; to: string }
  ) => {
    try {
      this.isLoading = true
      const response = await creativeAnalyticsService.getADSCards(
        accountId,
        datePair
      )
      this.adsCardsData = response.data.data ?? []
    } catch (error) {
      return "Get ad-creatives list error"
    } finally {
      this.isLoading = false
    }
    return null
  }

  @action getKPITrends = async (
    accountId: ID,
    adID: string,
    datePair: { from: string; to: string; periodicity: string }
  ) => {
    try {
      this.isKPILoading = true
      const response = await creativeAnalyticsService.getKPITrends(
        accountId,
        adID,
        datePair
      )
      this.KPITrendsData = response.data.data ?? {}
    } catch (error) {
      return "GET KPI trends error"
    } finally {
      this.isKPILoading = false
    }
    return null
  }

  @action getDropOffHistogram = async (accountId: ID, adID: string) => {
    try {
      this.isDropOffHistogramLoading = true
      const response = await creativeAnalyticsService.getDropOffHistogram(
        accountId,
        adID
      )
      this.dropOffHistogramData = response.data.data ?? []
    } catch (error) {
      return "Get DropOffHistogram error"
    } finally {
      this.isDropOffHistogramLoading = false
    }
    return null
  }

  @action createReport = async (accountId: ID, data: ReportEntitySettings) => {
    try {
      await creativeAnalyticsService.createReport(accountId, data)
    } catch (error: any) {
      this.error =
        error?.response?.data?.error || "Can't create a report setting"
    }
    return null
  }

  @action getReport = async (accountId: ID, reportID: number) => {
    try {
      this.isLoading = true
      const response = await creativeAnalyticsService.getReport(
        accountId,
        reportID
      )
      this.reportData = response.data.data ?? null
    } catch (error: any) {
      this.error =
        error?.response?.data?.error || "Can't get the report setting"
    } finally {
      this.isLoading = false
    }
    return null
  }

  @action cleanCurrentReport = () => {
    this.reportData = null
  }

  @action deleteReport = async (accountId: ID, reportID: number) => {
    try {
      await creativeAnalyticsService.deleteReport(accountId, reportID)
    } catch (error: any) {
      this.error = error?.response?.data?.error || "Can't delete the report"
    }
    return null
  }

  @action updateReport = async (
    accountId: ID,
    reportID: number,
    data: ReportEntitySettings
  ) => {
    try {
      this.isReportLoading = true
      await creativeAnalyticsService.updateReport(accountId, reportID, data)
    } catch (error: any) {
      this.error =
        error?.response?.data?.error || "Can't create a report setting"
    } finally {
      this.isReportLoading = false
    }
    return null
  }

  @action updateReportName = async (
    accountId: ID,
    reportID: number,
    name: string
  ) => {
    try {
      this.isReportLoading = true
      await creativeAnalyticsService.updateReportName(accountId, reportID, name)
    } catch (error: any) {
      this.error =
        error?.response?.data?.error || "Can't update the report name"
    } finally {
      this.isReportLoading = false
    }
    return null
  }

  @action getReportsList = async (accountId: ID) => {
    try {
      const response = await creativeAnalyticsService.getReportsList(accountId)
      this.reportsList = response.data.data ?? []
    } catch (error: any) {
      this.error = error?.response?.data?.error || "Can't get the reports list"
    }
    return null
  }
}

export default CreativeAnalyticsStore
