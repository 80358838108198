import { AxiosResponse } from "axios"
import config from "@root/config"
import HttpService from "@services/http.service"
import { ID } from "@framework/types/types"
import {
  AnalyticsCard,
  DropOffHistogramEntity,
} from "@framework/types/creativeAnalytics"
import {
  PerformanceReport,
  PerformanceReportV1,
} from "@framework/types/metrics"
import { ViewType } from "@pages/MetaAdsChannel/types"

export type ADSAnalyticsGroupType = "ByCreative" | "ByName" | "ByTitle"
export type ADSAnalyticsGroupTypeEntity = {
  Name: string
  Performance: PerformanceReport
  Ads: string[]
}
export type ADSAnalyticsGrouping = {
  [key in ADSAnalyticsGroupType]: ADSAnalyticsGroupTypeEntity[]
}
export interface ADSAnalyticsResponse {
  data: {
    AdCreatives: AnalyticsCard[]
    Summary: PerformanceReportV1
    Groupings: ADSAnalyticsGrouping
  }
}
interface DateEntry {
  [date: string]: PerformanceReport[]
}
export interface KPITrendsResponse {
  data: DateEntry
}

export interface DropOffHistogramResponse {
  data: DropOffHistogramEntity[]
}

export interface ReportEntitySettings {
  name: string
  time_period: {
    start_date: string
    end_date: string
    range_type: string
    range_value: string
  }
  filters: {
    name: string
    comparator: string
    value: string
  }[]
  group: ADSAnalyticsGroupType | "None"
  metrics: string[]
  view_mode: ViewType
  sort_option: {
    type: string
    order: "desc" | "asc"
  } | null
}
export interface ReportEntity {
  id: number
  property_id: number
  is_read_only: boolean
  is_default: boolean
  settings: ReportEntitySettings
}
export interface ReportResponse {
  data: ReportEntity
}
export interface ReportListResponse {
  data: ReportEntity[]
}

class CreativeAnalyticsAPI extends HttpService {
  getADSCards = (
    accountId: ID,
    params: { from: string; to: string }
  ): Promise<AxiosResponse<ADSAnalyticsResponse>> =>
    this.get(`property/${accountId}/meta/ad-creatives`, params)

  getKPITrends = (
    accountId: ID,
    adID: ID,
    params: { from: string; to: string; periodicity: string }
  ): Promise<AxiosResponse<KPITrendsResponse>> =>
    this.get(`property/${accountId}/meta/ads/${adID}/kpi-trends`, params)

  getDropOffHistogram = (
    accountId: ID,
    adID: ID
  ): Promise<AxiosResponse<DropOffHistogramResponse>> =>
    this.get(`property/${accountId}/meta/ads/${adID}/drop-off-histogram`)

  createReport = (
    accountId: ID,
    data: ReportEntitySettings
  ): Promise<AxiosResponse<any>> =>
    this.post(`property/${accountId}/meta/ads/report-settings`, data)

  getReport = (
    accountId: ID,
    reportID: number
  ): Promise<AxiosResponse<ReportResponse>> =>
    this.get(`property/${accountId}/meta/ads/report-settings/${reportID}`)

  deleteReport = (
    accountId: ID,
    reportID: number
  ): Promise<AxiosResponse<any>> =>
    this.delete(`property/${accountId}/meta/ads/report-settings/${reportID}`)

  updateReportName = (
    accountId: ID,
    reportID: number,
    name: string
  ): Promise<AxiosResponse<any>> =>
    this.patch(`property/${accountId}/meta/ads/report-settings/${reportID}`, {
      name,
    })

  updateReport = (
    accountId: ID,
    reportID: number,
    data: ReportEntitySettings
  ): Promise<AxiosResponse<any>> =>
    this.put(`property/${accountId}/meta/ads/report-settings/${reportID}`, data)

  getReportsList = (
    accountId: ID
  ): Promise<AxiosResponse<ReportListResponse>> =>
    this.get(`property/${accountId}/meta/ads/report-settings`)
}

export default new CreativeAnalyticsAPI({
  API_URL: config.API_ROOT,
})
